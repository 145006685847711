import type { Sku } from '@commercelayer/sdk'

export const useGiftDetailSideSlide = () => {
  const giftDetailSideSlideState = useState<Sku | undefined>(
    'gift-detail-side-slide-status'
  )
  const setGiftDetailSideSlideState = (props: Sku | undefined) => {
    giftDetailSideSlideState.value = props
  }

  return {
    giftDetailSideSlideState,
    setGiftDetailSideSlideState,
  }
}
